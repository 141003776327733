var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_vm._v(" Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia tempore enim quibusdam eligendi nemo reiciendis maxime sit earum deleniti ratione nobis possimus nam perferendis ullam cupiditate explicabo aspernatur ut, autem facilis? Officia reprehenderit quis, harum, reiciendis unde et eos enim similique rem, atque molestiae veritatis porro beatae voluptatum quae consectetur aspernatur sit quo facere dicta id nulla pariatur? Voluptates, nam aliquam, provident incidunt voluptatem alias possimus dicta maxime id animi tempore eius aperiam ipsam quo facere, distinctio commodi molestiae cum. ")]),_c('div',{attrs:{"id":"printMe"}},[_c('table',{staticStyle:{"width":"100%"},attrs:{"cellpadding":"5"}},[_vm._m(0),_vm._m(1),_c('tr',[_c('th',[_vm._v("№")]),_c('th',[_vm._v("Наименование")]),_c('tth',[_vm._v("Кол-во")])],1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6)])]),_c('button',{on:{"click":_vm.print}},[_vm._v("sfsfsf")])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td'),_c('td',{staticStyle:{"text-align":"center","width":"100%","font-size":"25px"}},[_vm._v("\"Ели Млели\"")]),_c('td')])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td'),_c('td',{staticStyle:{"text-align":"center","width":"100%","font-size":"25px"}},[_vm._v("пер. Доломановский, 70Д")]),_c('td')])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_vm._v("1")]),_c('td',[_vm._v("Наименование")]),_c('td',[_vm._v("10")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_vm._v("2")]),_c('td',[_vm._v("Наименование")]),_c('td',[_vm._v("10")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_vm._v("3")]),_c('td',[_vm._v("Наименование Наименование")]),_c('td',[_vm._v("10")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticStyle:{"font-size":"30px"}},[_vm._v("Итог:")]),_c('td'),_c('td',{staticStyle:{"font-size":"30px"}},[_vm._v("320.00")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{attrs:{"colspan":"2","width":"100%"}},[_vm._v("----------------------------")]),_c('td'),_c('td')])
}]

export { render, staticRenderFns }